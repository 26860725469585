<template>
  <nav
    v-if="totalPages > 0"
    class="zpagination"
    :class="`text-${align}`"
  >
    <ul>
      <li v-if="!disableNextPrevButtons">
        <ZButton
          :disabled="currentPage === 1"
          variant="primary"
          pills
          outline
          @click="prev"
        >
          {{ $t('actions.prev') }}
        </ZButton>
      </li>
      <template v-if="showFirstPage">
        <li>
          <ZButton
            variant="primary"
            pills
            outline
            @click="change(1)"
          >
            1
          </ZButton>
        </li>
        <li class="dots">
          <ZButton
            variant="primary"
            pills
            outline
            disabled
          >
            ...
          </ZButton>
        </li>
      </template>
      <li
        v-for="index in pagination"
        :key="index"
        :class="{ current: index + 1 === currentPage }"
      >
        <ZButton
          :variant="index + 1 === currentPage ? 'highlight' : 'primary'"
          pills
          :outline="index + 1 !== currentPage"
          @click="change(index + 1)"
        >
          {{ index + 1 }}
        </ZButton>
      </li>
      <li v-if="!disableNextPrevButtons">
        <ZButton
          :disabled="currentPage === totalPages"
          variant="primary"
          pills
          outline
          @click="next"
        >
          {{ $t('actions.next') }}
        </ZButton>
      </li>
    </ul>
  </nav>
</template>

<script setup lang="ts">
import type { PaginationAlignment } from '~/types/style-guide'

const props = withDefaults(defineProps<{
  currentPage: number
  total: number
  pageSize: number
  disableNextPrevButtons?: boolean
  align?: PaginationAlignment
}>(), {
  disableNextPrevButtons: false,
  align: 'center',
})

const totalPages = computed(() => Math.ceil(props.total / props.pageSize))

const { isMobile } = useDevice()
const limit = isMobile ? 3 : 5
const steps = isMobile ? 1 : 2

const pagination = computed(() => {
  const pages = Array.from(Array(totalPages.value).keys())

  if (props.currentPage <= steps) {
    return pages.slice(0, limit)
  }

  if (props.currentPage > steps && pages.length > limit) {
    return pages.slice(props.currentPage - (steps + 1), props.currentPage + steps)
  }

  return pages
})

const showFirstPage = computed(() => props.currentPage > limit)

const emit = defineEmits<{
  change: [index: number]
}>()

function change(index: number) {
  if (props.currentPage !== index) {
    emit('change', index)
  }
}

function prev() {
  if (props.currentPage > 1) {
    change(props.currentPage - 1)
  }
}

function next() {
  change(props.currentPage + 1)
}
</script>
